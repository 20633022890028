import { useAuth } from 'contexts/auth'
import { UserProvider, useUser } from 'contexts/user'

const HomePage = () => {
  const { user } = useAuth()

  return (
    <div className="space-y-6">
      <h1 className="text-k/44_110 font-medium md:text-k/36_110">Welcome!</h1>

      {user && (
        <UserProvider user={user}>
          <UserInfo />
        </UserProvider>
      )}
    </div>
  )
}

export default HomePage

const UserInfo = () => {
  const { user } = useUser()

  return (
    <div>
      <p>ID: {user.id}</p>
      <p>Name: {user.info.name}</p>
      <p>Email: {user.info.email}</p>
    </div>
  )
}
