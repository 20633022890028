import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { jwtDecode } from 'jwt-decode'
import { JWTPayload, UserV1, useUserV1 } from '@tovala/browser-apis-combinedapi'
import { UNAUTHORIZED_EVENT_NAME } from '@tovala/browser-apis-core'

import { getCookie, removeCookie, setCookie } from 'utils/storage'
import { identifyUser, identifyUserFromJWT } from 'utils/analytics'
import { isDateBefore } from 'utils/dates'

interface Auth {
  hadUnauthEvent: boolean
  isLoadingUser: boolean
  isLoggedIn: boolean
  loadUserError: Error | null
  onJWTChanged(newJWT: string | null): void
  user: UserV1 | undefined
}

const AuthContext = createContext<Auth | undefined>(undefined)

function getJWTExpirationDate(decodedJWT: JWTPayload) {
  return new Date(decodedJWT.exp * 1000)
}

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [hadUnauthEvent, setHadUnauthEvent] = useState(false)
  const [jwt, setJwt] = useState<string | null>(() => {
    const jwt = getCookie('JWT_TOKEN') ?? null
    if (!jwt) {
      return null
    }

    const decodedJWT = jwtDecode<JWTPayload>(jwt)
    const isExpired = isDateBefore(getJWTExpirationDate(decodedJWT), new Date())

    return isExpired ? null : jwt
  })
  const [userID, setUserID] = useState<number | undefined>(undefined)

  const {
    data: user,
    error: loadUserError,
    isError: hasLoadUserError,
    isLoading: isLoadingUser,
  } = useUserV1({ userID })

  useEffect(() => {
    manageCookieForJWT(jwt)

    if (jwt) {
      const decodedJWT = jwtDecode<JWTPayload>(jwt)

      setUserID(decodedJWT.userId)

      identifyUserFromJWT({ decodedJWT })
    } else {
      setUserID(undefined)
    }
  }, [jwt])

  useEffect(() => {
    function handleUnauthorizedEvent() {
      setHadUnauthEvent(true)
      setJwt(null)
    }

    document.addEventListener(UNAUTHORIZED_EVENT_NAME, handleUnauthorizedEvent)

    return () => {
      document.removeEventListener(
        UNAUTHORIZED_EVENT_NAME,
        handleUnauthorizedEvent,
      )
    }
  }, [])

  useEffect(() => {
    if (user) {
      identifyUser({
        email: user.info.email,
        id: user.id,
        name: user.info.name,
      })
    }
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        hadUnauthEvent,
        isLoadingUser: !!userID && isLoadingUser,
        isLoggedIn: !!jwt,
        loadUserError: hasLoadUserError ? loadUserError : null,
        onJWTChanged: (newJWT: string | null) => {
          manageCookieForJWT(newJWT)
          setJwt(newJWT)

          if (newJWT) {
            setHadUnauthEvent(false)
          }
        },
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function manageCookieForJWT(jwt: string | null) {
  if (jwt) {
    const decodedJWT = jwtDecode<JWTPayload>(jwt)

    setCookie({
      content: jwt,
      cookieName: 'JWT_TOKEN',
      options: {
        expires: getJWTExpirationDate(decodedJWT),
        sameSite: 'lax',
      },
    })
  } else {
    removeCookie('JWT_TOKEN')
  }
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used in an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
