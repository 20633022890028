import { createContext, ReactNode, useContext } from 'react'
import { UserV1 } from '@tovala/browser-apis-combinedapi'

interface IUser {
  user: UserV1
}

const UserContext = createContext<IUser | undefined>(undefined)

const UserProvider = ({
  children,
  user,
}: {
  children: ReactNode
  user: UserV1
}) => {
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used in a UserProvider')
  }

  return context
}

export { UserProvider, useUser }
