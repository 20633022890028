import { ButtonLoading, FormGroup, Input } from '@tovala/component-library'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useLogin } from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'

import { useAuth } from 'contexts/auth'
import { canAccessOATS } from 'utils/auth'

const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const { hadUnauthEvent, isLoggedIn, onJWTChanged } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {
    error: loginError,
    isError: hasLoginError,
    isLoading: isLoggingIn,
    mutate: login,
  } = useLogin({
    onSuccess: (data) => {
      const jwt = data.token

      // Non-employee users will be able to call the login endpoint successfully
      // but the JWT will indicate they can't log in as an admin.
      if (canAccessOATS(jwt)) {
        onJWTChanged(jwt)

        const queryParams = new URLSearchParams(location.search)

        navigate(queryParams.get('redirect') || '/')
      } else {
        alert('You are not authorized to login.')
      }
    },
  })

  if (isLoggedIn) {
    return <Navigate to={searchParams.get('redirect') || '/'} />
  }

  // If we have an error on this page logging in, we don't want to duplicate error displays. But
  // if we don't have an error and there was an unauthorized event, then we want to show a message
  // to the user that their session expired.
  const displayDidSessionExpire = !loginError && !isLoggingIn && hadUnauthEvent

  return (
    <div>
      <div className="flex justify-center">
        <div className="mx-auto w-[320px] sm:w-full">
          <h2 className="mb-12 text-center text-k/36_110 font-medium md:mb-10 md:text-k/32_105">
            Login
          </h2>
          <form
            onSubmit={(event) => {
              event.preventDefault()

              login({ email, password })
            }}
          >
            <div className="space-y-6 sm:space-y-4">
              {displayDidSessionExpire ? (
                <p>Please log in again. Your session has expired.</p>
              ) : null}

              <FormGroup label="Email" labelFor="email">
                <Input
                  id="email"
                  name="email"
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  type="email"
                  value={email}
                />
              </FormGroup>

              <FormGroup label="Password" labelFor="password">
                <Input
                  id="password"
                  name="password"
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  type="password"
                  value={password}
                />
              </FormGroup>

              {hasLoginError && (
                <p>There was an error logging you in: {loginError.message}.</p>
              )}
            </div>
            <div className="mt-8 flex justify-center sm:mt-6">
              <ButtonLoading isLoading={isLoggingIn} size="large">
                Login
              </ButtonLoading>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
