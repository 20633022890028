import {
  addDays,
  addMonths,
  addYears,
  isBefore,
  parse,
  parseISO,
} from 'date-fns'

export enum DATE_FORMATS {
  API_DATE_FORMAT = 'yyyy-MM-dd',
  DEFAULT_DATE_FORMAT = 'M/d/yyyy',
  DEFAULT_DATETIME_FORMAT = 'M/d/yyyy h:mm:ss aaa',
}

export type DateUnits = 'days' | 'months' | 'years'

export function addToDate(
  date: Date,
  { quantity, units }: { quantity: number; units: DateUnits }
): Date {
  if (units === 'days') {
    return addDays(date, quantity)
  } else if (units === 'months') {
    return addMonths(date, quantity)
  }

  return addYears(date, quantity)
}

export function isDateBefore(firstDate: Date, secondDate: Date): boolean {
  return isBefore(firstDate, secondDate)
}

/**
 * Parses the provided value to a Date. By default, this will try to parse the provided
 * value as an ISO date. If you provide a parseFormat, it will try to parse using that
 * format instead.
 */
export function parseToDate(
  value: string,
  { parseFormat = null }: { parseFormat?: DATE_FORMATS | null } = {}
): Date {
  return parseFormat ? parse(value, parseFormat, new Date()) : parseISO(value)
}
