import axios from 'axios'
import * as Sentry from '@sentry/react'
import {
  isAxiosResponseError,
  setupAxiosRequestInterceptor,
  setupAxiosResponseInterceptor,
} from '@tovala/browser-apis-core'

setupAxiosRequestInterceptor()
setupAxiosResponseInterceptor()

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isAxiosResponseError(error) && error.response?.status !== 401) {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  }
)
