import { useEffect } from 'react'

import { useAuth } from 'contexts/auth'

const LogoutPage = () => {
  const { onJWTChanged } = useAuth()

  useEffect(() => {
    onJWTChanged(null)
  }, [onJWTChanged])

  return null
}

export default LogoutPage
