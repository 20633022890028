import Analytics from 'analytics'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Can't find types for this plugin right now.
import segmentPlugin from '@analytics/segment'
import * as Sentry from '@sentry/react'

import { getEnvVar } from './env'

const CODE_VERSION = getEnvVar('VERCEL_GIT_COMMIT_SHA')
const APP_NAME = getEnvVar('APP_NAME')

export const analytics = Analytics({
  app: APP_NAME,
  plugins:
    getEnvVar('APP_ENV') === 'test'
      ? []
      : [
          segmentPlugin({
            writeKey: getEnvVar('SEGMENT_WRITE_KEY'),
          }),
        ],
})

export function identifyUser(user: {
  email: string
  id: number
  name: string
}): void {
  analytics.identify(`${user.id}`, {
    email: user.email,
    name: user.name,
    system: APP_NAME,
    version: CODE_VERSION,
  })

  Sentry.getCurrentScope().setTag('id', `${user.id}`)
  Sentry.getCurrentScope().setTag('version', CODE_VERSION)
}

export function identifyUserFromJWT({
  decodedJWT,
}: {
  decodedJWT: { userId: number }
}): void {
  analytics.identify(`${decodedJWT.userId}`, {
    system: APP_NAME,
    version: CODE_VERSION,
  })

  Sentry.getCurrentScope().setTag('id', `${decodedJWT.userId}`)
  Sentry.getCurrentScope().setTag('version', CODE_VERSION)
}
